import React, { useEffect, useState, createRef, Component } from "react";

export default function Privacy() {
  var email = React.createRef();
  var subject = React.createRef();
  var message = React.createRef();
  const [msg, setResponse] = useState(<p></p>);

  // ### RESET UI ON PAGE CHANGE
  useEffect(() => {
    window.scrollTo(0, 0);
    let main = document.getElementById("main");
    if(main)
    {
      main.classList.add("background");
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch('https://www.chamberscreative.co.uk/send_email.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email.current.value,
          subject: subject.current.value,
          message: message.current.value,
        }),
      });

      const data = await response.json();
      setResponse(<p className="success">{data.message}</p>);
    } catch (error) {
      console.log(error)
      setResponse(<p className="error">Sorry, there has been an issue</p>);
    }
  };

  return (
    <>
      <div className="privacy fade-in">
        <div className="container">
          <h2>Privacy Policy</h2>
          <p><i>At Chambers Creative, we are committed to maintaining the trust and confidence of our visitors to our web site. In particular, we want you to know that we are not in the business of selling, renting or trading email lists with other companies and businesses for marketing purposes. We just don’t do that sort of thing. in this Privacy Policy, we have provided information on when and why we collect your personal information, how we use it, the limited conditions under which we may disclose it to others and how we keep it secure.</i></p>
          <br/>
          <h2>The purposes of processing</h2>
          <p>The Data concerning the User is collected to allow the Owner to provide its Services, as well as for the following purposes: <b>Contacting the User.</b></p> 
          <br/>
          <h2>Types of data collected</h2>
          <p><b>Contact Form</b></p>
          <p>By filling in the contact form with their Data, the User authorizes this Application to use these details to reply to requests for information, quotes or any other kind of request as indicated by the form’s header.</p> 
          <p>Personal Data collected: email address, first name and last name. </p>
          <p>These details are kept to contact you if we need to obtain or provide additional information; to check our records are right and to check every now and then that you’re happy and satisfied. We don't rent or trade email lists with other organisations and businesses.</p>
          <br/>
          <h2>Access to your personal information</h2>
          <p>Under GDPR, the data subject has the right to access and amend any of their personal data that you hold.</p> 
          <p>You are entitled to view, amend, or delete the personal information that we hold. Email your request to tony at chambersltony80@gmail.com</p>
          <br/>
          <h2>Changes to this Privacy Notice</h2>
          <p>Kevs decorating reserves the right to make changes to this privacy policy at any time by giving notice to its Users on this page and possibly within this Application and/or - as far as technically and legally feasible - sending a notice to Users via any contact information available to the Owner. It is strongly recommended to check this page often, referring to the date of the last modification listed at the bottom. </p>
          <p>Should the changes affect processing activities performed on the basis of the User’s consent, the Owner shall collect new consent from the User, where required. 
          </p> 
          <br/>
        </div>
      </div>
    </>
  );
}

